jQuery(document).ready(function($) {
    // Smooth scrolling for header buttons
    $('a.header__button').click(function(e) {
        e.preventDefault();
        var targetId = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(targetId).offset().top
        }, 1000);
    });

});